import { ActivityIndicator, Button, Link, Menu, MenuTrigger, MenuItem } from '@troon/ui';
import { A, createAsync } from '@solidjs/router';
import { IconCaretDownMd, IconCreditCard01, IconGlobe, IconStar, IconUserSquare } from '@troon/icons';
import { For, Show, Suspense, splitProps } from 'solid-js';
import { Title } from '@solidjs/meta';
import { Dynamic } from 'solid-js/web';
import { Content } from '../components/content';
import { requireLoggedIn } from '../providers/user';
import { ErrorBoundary } from '../components/error-boundary';
import { GenericLayout } from '../layouts/generic';
import type { RouteSectionProps } from '@solidjs/router';
import type { ComponentProps } from 'solid-js';

const userNav: Array<ComponentProps<typeof MenuItem>> = [
	{ icon: IconStar, href: '/account/', children: 'My account' },
	{ icon: IconGlobe, href: '/account/activity/', children: 'Activity' },
	{ icon: IconUserSquare, href: '/account/profile/', children: 'Profile' },
	{ icon: IconCreditCard01, href: '/account/payment-methods/', children: 'Payment methods' },
];

export default function Account(props: RouteSectionProps) {
	const user = createAsync(() => requireLoggedIn());

	return (
		<GenericLayout>
			<Title>My account | Troon</Title>
			<Suspense>
				<Show when={user() !== null}>
					<Content small>
						<header class="mb-4 flex flex-row flex-wrap items-center justify-between gap-4 border-b border-neutral-500 pb-4 md:mb-8 md:gap-8 md:pb-8">
							<div class="flex grow flex-col gap-4">
								<div class="font-semibold">{user()?.userRewards.level}</div>
								<div class="text-4xl font-semibold md:text-5xl">
									{user()?.me.firstName} {user()?.me.lastName}
								</div>
								<div>Troon rewards #{user()?.me.troonRewardsId}</div>
							</div>
							<div class="hidden grow-0 md:block">
								<Button as={Link} href="/auth/logout/" appearance="secondary">
									Log out
								</Button>
							</div>
							<div class="w-full grow lg:hidden">
								<MenuTrigger>
									<Button
										as={Link}
										href="/account/"
										appearance="secondary-current"
										class="justify-between rounded border bg-neutral-200 normal-case text-neutral-700"
									>
										Account
										<IconCaretDownMd class="text-brand" />
									</Button>
									<Menu>
										<For each={userNav}>{(item) => <MenuItem {...item} />}</For>
										<MenuItem class="justify-center text-center font-semibold uppercase" href="/auth/logout/">
											Log out
										</MenuItem>
									</Menu>
								</MenuTrigger>
							</div>
						</header>

						<div class="grid w-full grid-cols-1 gap-4 lg:grid-cols-12 lg:gap-8">
							<div class="col-span-1 row-start-2 hidden lg:col-span-4 lg:row-start-1 lg:block">
								<nav class="rounded-lg border border-neutral-500 p-2 md:p-4">
									<ul class="flex flex-col gap-2">
										<For each={userNav}>{(props) => <NavItem {...props} />}</For>
									</ul>
								</nav>
							</div>
							<div class="col-span-1 lg:col-span-8">
								<Suspense fallback={<ActivityIndicator>Loading…</ActivityIndicator>}>
									<ErrorBoundary>{props.children}</ErrorBoundary>
								</Suspense>
							</div>
						</div>
					</Content>
				</Show>
			</Suspense>
		</GenericLayout>
	);
}

function NavItem(allProps: ComponentProps<typeof MenuItem>) {
	const [iconProps, props] = splitProps(allProps, ['icon']);
	return (
		<li>
			<A
				href={props.href!}
				activeClass="bg-brand-100 text-brand"
				class="flex items-center gap-x-2 rounded-lg px-3 py-2 outline-none transition-colors duration-200 hover:bg-brand-100 focus-visible:ring-4 focus-visible:ring-brand-100"
				end
			>
				<Show when={iconProps.icon}>
					<Dynamic component={iconProps.icon!} />
				</Show>
				{props.children}
			</A>
		</li>
	);
}
